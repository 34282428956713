export const {
  NX_ADOBE_ANALYTICS_ENV = 'dev',
  NX_ADOBE_ANALYTICS_RSID = 'adbedexchangedev',
  NX_V3_API_URL = 'https://edex-preprod.adobe.io',
  NX_UI_URL = 'https://preprod.edex.adobe.com',
  NX_CDN_HOST = 'https://preprod-cdn.edex.adobe.com',
  NX_CDN_S3_BUCKET = 'edex-cdn-v3-preprod-ue1',
  NX_ADOBE_PRIVACY_SCRIPT = 'https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js',
  NX_COOKIE_DOMAIN = 'edex.adobe.com',
  NX_FULLSTORY_ORGID = '2AP7Y',
  NX_ONETRUST_ENV = 'dev',
  NX_ONETRUST_DOMAIN_ID = '7a5eb705-95ed-4cc4-a11d-0cc5760e93db',
  NX_GOOGLE_CLIENT_ID = '296748428399-fibkrcohmdvq502su48pnnhr5535fq24.apps.googleusercontent.com',
  NX_GOOGLE_API_KEY = 'AIzaSyA7PT3tdzwBhddpRn2bsR97nLh92paiDBU',
  NX_MS_CLIENT_ID = '0f11af13-25c4-42ba-bbc7-b390728a51e6',
  NX_VERSION = '9.6.0',
  NX_EDU_ASSET_BROWSER_URL = 'https://spark-cs.adobe.io',
  NX_PROJECTX_ASSET_BROWSER_URL = 'https://new.express.adobe.com',
  NX_GNEISS_METADATA_URL = 'https://platform-cs-va6c2.adobe.io/content/storage/id',
  NX_ENABLE_PROJECT_X_ASSET_BROWSER = 'true',
  NX_CCX_HOST = 'https://express.adobe.com',
  NX_TR_REDESIGN_ENABLED = false,
  NX_ENABLE_NATIVE_SHARE = 'false',
  NX_RAPI_FLAG = 'true',
  NX_SCHOOL_DISTRICT = 'true',
  NX_IMS_SIGNUP_SUSI_DCTX_ID = 'v:2,s,6da1ba90-2aef-11ee-8013-b7180e66dabc',
  NX_IMS_SIGNIN_SUSI_DCTX_ID = 'v:2,s,2620b760-2af0-11ee-bf30-d1bfb17b1fb8',
  NX_IMS_CLIENT_ID = 'edexchange2',
} = process.env;

export const dotenv = {
  NX_ADOBE_ANALYTICS_ENV,
  NX_ADOBE_ANALYTICS_RSID,
  NX_FULLSTORY_ORGID,
  NX_CDN_HOST,
  NX_CDN_S3_BUCKET,
  NX_ADOBE_PRIVACY_SCRIPT,
  NX_COOKIE_DOMAIN,
  NX_ONETRUST_ENV,
  NX_ONETRUST_DOMAIN_ID,
  NX_GOOGLE_CLIENT_ID,
  NX_GOOGLE_API_KEY,
  NX_MS_CLIENT_ID,
  NX_V3_API_URL,
  NX_UI_URL,
  NX_VERSION,
  NX_EDU_ASSET_BROWSER_URL,
};

export const environment = {
  ...dotenv,
  production: true,
  api: NX_V3_API_URL,
};
